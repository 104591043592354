<template>
  <div class="search">
    <div class="banner">
      <div class="mask"></div>
      <div style="z-index: 2; position: relative">
        <div class="banner__title">少年财商素质教育导师</div>
        <div class="points-title">证书查询</div>
        <div class="banner__form">
          <div class="banner__input">
            <input type="text" v-model="search" @keypress.enter="searchTeacher">
          </div>
          <div class="banner__btn" @click="searchTeacher"></div>
        </div>
      </div>
    </div>

    <div class="points-title" style="margin: 68px auto 48px; color: #2d3233; text-align: center">学员风采</div>

    <div class="color-box__container">
      <img class="color-box" src="../../assets/img/search/search-photo-1.png">
      <img class="color-box" src="../../assets/img/search/search-photo-2.png">
      <img class="color-box" src="../../assets/img/search/search-photo-3.png">
      <img class="color-box" src="../../assets/img/search/search-photo-4.png">
    </div>
  </div>
</template>

<script>
  export default {
    name: "Search",
    data() {
      return {
        search: ''
      }
    },
    methods: {
      searchTeacher() {
        this.$router.push(`/search-result?search=${this.search}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
$red: #e40073;
$green: #95c51b;
$yellow: #ffe100;
$blue: #00abeb;
.search {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  min-width: 1900px;
}
.banner {
  height: 600px;
  min-width: 1900px;
  width: 100%;
  background-image: url("../../assets/img/search/banner.webp");
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
}

.mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0068b7aa;
  z-index: 1;
}

.banner__title {
  font-size: 60px;
  color: $yellow;
  padding-top: 120px;
  letter-spacing: 10px;
}

.points-title {
  width: 379px;
  height: 48px;
  display: block;
  margin: 50px auto 80px;
  background-image: url("../../assets/img/search/points.png");
  background-size: 100%;
  background-position: center;

  color: $yellow;
  font-size: 36px;
  line-height: 48px;
}
// 搜索表单
  .banner__form {
    display: flex;
    width: 880px;
    margin: 0 auto;
  }
  // 输入框
  .banner__input {
    width: 800px;
    height: 60px;
    border-radius: 5px;
    background-color: #ffffff;
    input {
      font-size: 36px;
      line-height: 60px;
      width: 800px;
      padding: 0 18px;
      outline: none;
    }
  }
  // 搜索按钮
  .banner__btn {
    height: 60px;
    width: 60px;
    background-image: url("../../assets/img/search/search-white.png");
    background-size: 100%;
    margin-left: 20px;
    cursor: pointer;
  }

  // 彩色盒子
  .color-box__container {
    display: flex;
    width: 1320px;
    margin: 0 auto 160px;
    justify-content: space-between;
  }
  .color-box {
    width: 304px;
    height: 164px;
  }
</style>